.report-button {
    position: fixed;
    bottom: var(--distance-from-edge-default);
    width: 110px;
    right: var(--distance-from-edge-default);
    margin-bottom: 15px;
    padding-left: 10px;
    z-index: var(--zIndex-button);
    background-color: var(--incentive-blue);
    border: 2px solid var(--color-white);
}

.report-button p {
    color: #fff;
}

.report-button .plus {
    position: relative;
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-left: 10px;
}

.report-button .plus span {
    position: absolute;
    background-color: var(--color-white);
    border-radius: 1px;
}

.report-button .plus span:first-child {
    height: 2px;
    width: 14px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.report-button .plus span:last-child {
    width: 2px;
    height: 14px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
