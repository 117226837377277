section:has(> div#searchable-select-div) > .select-field-container {
    display: block;
    max-height: calc(100vh - 475px);
    overflow-y: auto;
}

section:has(> div#searchable-select-div) > .select-field-container > .select-field {
    margin-top: var(--margin-s);
    margin-bottom: var(--margin-s);
}

section img {
    width: 20px;
    height: 20px;
}

.search-input {
    position: absolute;
    transition: width var(--transition-default), opacity var(--transition-default), visibility var(--transition-default);
    opacity: 0;
    height: 1.5rem;
    right: 45px;
    width: 0;
    border-radius: var(--borderRadius-small);
    border: 1px solid var(--color-light-gray);
    font-size: var(--font-s);
    visibility: hidden;
    pointer-events: none;
}

.search-input.expanded {
    width: 50%;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.list-container {
    overflow-y: scroll;
    transition: ease-in-out var(--transition-default);
    max-height: calc(100vh - 475px);
    min-height: 40px;
}

.search-icon-button {
    background-color: transparent;
    width: 20px;
    height: 20px;
    min-width: 20px;
}
