.select-field-container {
    gap: var(--margin-s);
}

.select-field {
    display: flex;
    border: var(--border-weak);
    border-radius: var(--borderRadius-small);
    padding: var(--padding-s);
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    transition: border var(--transition-default);
}

.selected {
    border: 1px solid var(--incentive-blue);
}

.long-selector {
    overflow-x: visible;
    overflow-y: hidden;
}

.large-selector {
    height: 40px;
}

.medium-selector {
    height: 25px;
}

.small-selector {
    height: 20px;
}

.entity-type-selector {
    justify-content: center;
    align-items: center;
}
