.report-summary-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--padding-s);
}

.report-summary-modal-content > div:has(> .report-item) {
    margin: var(--margin-m) 0;
    width: 100%;
    border: 1px solid var(--color-gray);
    border-radius: var(--borderRadius-small);
    padding: 10px;
    background-color: var(--color-background-on-background);
}

.report-summary-modal-content > div .report-item {
    border: none;
}

.report-summary-modal-content > span {
    display: flex;
    align-items: center;
    gap: 10px;
}

.report-summary-modal-content span h1 {
    color: var(--incentive-blue);
    transition: content 0.1s ease-out;
}

.report-summary-modal-content img.no-filter {
    width: 20px;
    height: 20px;
}

.report-summary-modal-content > p {
    margin: 0;
    font-size: var(--font-s);
}

.report-summary-modal-content .disclaimer {
    margin-bottom: var(--margin-s);
}
