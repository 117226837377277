.layer-switcher {
    top: var(--distance-from-edge-default);
    right: var(--distance-from-edge-default);
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--height-streched-button);
    width: 100px;
}

.layer-switcher img {
    margin-left: var(--margin-xs);
}

.layer-options {
    top: var(--distance-from-edge-default);
    right: 135px;
    width: fit-content;
    height: 60px;
    padding: var(--padding-xs);
    opacity: 0;
    transition: var(--transition-default);
}

.layer-options.visible {
    z-index: var(--zIndex-modal);
    opacity: 1;
}

.layer-options div {
    width: 50px;
    height: 50px;
}

.layer-options p {
    text-align: center;
    font-size: var(--font-xxs);
}

.layer-options img {
    width: 35px;
    height: 35px;
    border: 2px solid transparent;
}

.layer-options img.active {
    border: 2px solid var(--color-primary);
    border-radius: var(--borderRadius-medium);
}
