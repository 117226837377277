.contact-section {
    z-index: 4; /* No variable as this is an exception */
}

.contact-section ul {
    margin: 0;
}

.contact-section li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--padding-s) 0;
}

.contact-section li img,
.contact-section li p {
    margin-right: var(--margin-s);
}

.contact-section li div {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.contact-section li p {
    font-weight: 600;
}

.contact-section .profile-picture {
    border-radius: 50%;
}

.contact-section img {
    width: 20px;
    height: 20px;
}
