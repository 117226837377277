.loading-placeholder {
    background-color: var(--color-gray);
    width: 100%;
    height: 100%;
    border-radius: var(--borderRadius-small);
    min-width: 20px;
    min-height: 20px;
    position: relative;
    overflow: hidden;
}

.loading-animation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0) 100%
    );
    animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}
