.marker-modal.info-popup.modal .direction-line {
    justify-content: flex-start;
    gap: var(--margin-s);
}

.marker-modal.info-popup.modal .description {
    background-color: var(--color-gray);
    border-radius: var(--borderRadius-small);
    padding: var(--padding-s);
    margin-top: var(--margin-m);
    width: fit-content;
    color: var(--color-white);
    font-size: var(--font-xs);
    border-left: 4px solid var(--color-primary);
}

@keyframes button-pulse {
    0%,
    71.4% {
        transform: scale(1);
    }
    14.3% {
        transform: scale(1.05);
    }
    28.6% {
        transform: scale(1);
    }
}

.marker-modal.info-popup .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: var(--margin-s);
    color: var(--color-primary);
    width: 100%;
}

.marker-modal.info-popup .distance {
    font-size: var(--font-xs);
    min-width: 100px;
}

.marker-modal.info-popup .disclaimer {
    text-align: right;
}

.marker-modal.info-popup .disclaimer.visible {
    opacity: 1;
}

.marker-modal.info-popup .invite-link {
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
}

.marker-modal.info-popup .reports-count {
    font-size: var(--font-xs);
    color: var(--color-primary);
}
