@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400 600 700;
    font-display: swap;
    src: url('./Raleway/Raleway-VariableFont_wght.ttf') format('ttf');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 400 600 700;
    src: url('./Raleway/Raleway-Italic-VariableFont_wght.ttf') format('ttf');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

:root {
    --color-primary: #dee2e6;
    --color-background: #232323;
    --color-blue: #0075ff;
    --incentive-blue: #0f90ff;
    --color-red: #ff0017f5;
    --color-gray: #636363;
    --color-box-text: #807f7f;
    --color-gray-on-dark-background: #959494;
    --color-light-gray: #d2d2d2;
    --color-light-gray-hover: #b3b3b3;
    --color-white: #ffffff;
    --color-risk-0: #7cc7ab;

    --font-xxxs: 0.5rem;
    --font-xxs: 0.75rem;
    --font-xs: 0.875rem;
    --font-s: 1rem;
    --font-m: 1.125rem;
    --font-l: 1.125rem;
    --font-xl: 1.5rem;

    --borderRadius-small: 4px;
    --borderRadius-medium: 12px;
    --borderRadius-default: 16px;

    --boxShadow-default: 0 8px 16px rgba(0, 0, 0, 0.5);
    --boxShadow-marker: 0 2px 4px rgba(0, 0, 0, 0.5);
    --boxShadow-marker-pulse: #ff69b400 0 0 0 16px;
    --boxShadow-light: 0 4px 8px rgba(0, 0, 0, 0.5);

    --zIndex-map: -1;
    --zIndex-button: 0;
    --zIndex-backdrop: 1;
    --zIndex-modal: 2;
    --zIndex-closeButton: 3;

    --transition-default: 0.25s;
    --transition-long: 0.5s;

    --padding-xxs: 2px;
    --padding-xs: 4px;
    --padding-s: 8px;
    --padding-m: 16px;
    --padding-l: 20px;
    --padding-xl: 24px;
    --padding-xxl: 32px;

    --margin-xxs: 2px;
    --margin-xs: 4px;
    --margin-s: 8px;
    --margin-m: 16px;
    --margin-l: 24px;
    --margin-xl: 32px;

    --text-relation-strong: 1.6%;
    --text-relation-weak: 3.2%;

    --border-default: 2px solid #000;
    --border-weak: 1px solid #000;
    --border-marker: 3px solid #ffffff;

    --distance-from-edge-default: 15px;
    --button-size: 50px;
    --height-streched-button: 45px;
}

/* ELEMENT STYLES */

body,
textarea,
input[type='text'] {
    margin: 0;
    font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    font-size: 16px; /* Min font size to avoid zoom on mobile */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3,
h4 {
    font-weight: 700;
    line-height: 30px;
    margin: 0;
    color: var(--color-primary);
}

h1 {
    font-size: var(--font-xl);
}

h2 {
    font-size: var(--font-l);
}

h3 {
    font-size: var(--font-m);
}

h4 {
    font-size: var(--font-s);
}

p {
    font-size: var(--font-s);
    margin: 0;
    color: var(--color-primary);
}

a {
    color: var(--color-primary);
}

strong {
    color: var(--color-primary);
}

label {
    color: var(--color-primary);
}

hr {
    border: 1px solid var(--color-gray-on-dark-background);
    border-radius: var(--borderRadius-small);
}

svg,
img[src$='.svg'] {
    filter: invert(1);
}

img.no-filter {
    filter: none !important;
}

ul {
    list-style: none;
    padding: 0;
}

button {
    width: 100%;
    height: 2.5em;
    background-color: var(--color-primary);
    border: none;
    border-radius: var(--borderRadius-small);
    color: var(--color-background);
    font-size: var(--font-s);
    font-weight: 600;
    text-align: center;
    min-width: 100px;
    transition: var(--transition-default);
    cursor: pointer;
}

button[type='submit'] {
    background-color: var(--incentive-blue);
    color: var(--color-white);
}

button:hover {
    filter: brightness(1.1);
}

button a {
    color: var(--color-primary);
}

button.action {
    background-color: var(--incentive-blue);
    color: var(--color-white);
    box-shadow: var(--boxShadow-default);
}

button a {
    color: var(--color-background);
    font-size: var(--font-s);
    text-decoration: none;
}

button:has(a) {
    background-color: var(--incentive-blue);
    box-shadow: var(--boxShadow-default);
}

span {
    color: var(--color-primary);
    user-select: none;
}

textarea {
    position: relative;
    font-size: 16px; /* Min font size to avoid zoom on mobile */
    font-weight: 400;
    border: 1px solid var(--color-light-gray);
    border-radius: var(--borderRadius-small);
    width: calc(100% - 16px);
    min-height: 2rem;
    resize: none;
    transition: ease-in-out var(--transition-default);
    padding: var(--padding-s);
}

textarea:hover {
    border: 1px solid var(--color-light-gray-hover);
}

textarea::placeholder {
    font-weight: 400;
    margin: var(--margin-m);
    color: var(--color-box-text);
}

/* CLASS STYLES */

.modal {
    position: fixed;
    left: 50%;
    background-color: var(--color-background);
    width: 60%;
    max-width: 600px;
    padding: var(--padding-m);
    border-radius: var(--borderRadius-default);
    z-index: var(--zIndex-modal);
    display: none;
    opacity: 0;
    border: var(--border-default);
    text-align: left;
    max-height: 70vh;
}

.container {
    top: 50%;
    transform: translate(-50%, -50%);
    overflow-y: auto;
    overflow-x: hidden;
}

.info-popup {
    left: 50%;
    transform: translateX(-50%);
    bottom: 15px;
}

.info-popup h1 {
    margin-bottom: var(--text-relation-strong);
}

.container > ol {
    padding: var(--padding-l);
    margin: 0;
}

.container > ol > li {
    margin-bottom: var(--margin-m);
}

.container > ol > li > p {
    margin-top: var(--margin-xs);
}

.container p {
    margin: var(--margin-s) 0;
}

.highlight {
    border: 2px solid var(--color-red) !important;
}

.red-highlight {
    font-size: var(--font-xl);
    color: var(--color-red);
}

.red-highlight#warning-span {
    font-size: var(--font-xs);
    margin-top: var(--margin-m);
}

.legal-text {
    overflow-y: scroll;
    max-height: calc(100vh - 25px);
    background-color: var(--color-background);
    padding: 25px;
}

.legal-text h2 {
    margin-top: var(--margin-m);
}

.legal-text li {
    color: var(--color-primary);
}

.line > strong {
    color: var(--color-white);
}

.disclaimer {
    font-size: var(--font-xxs);
    color: var(--color-primary);
}

.row {
    display: flex;
    gap: var(--margin-m);
}

.small-button {
    position: fixed;
    cursor: pointer;
    background-color: var(--color-background);
    border-radius: var(--borderRadius-default);
    box-shadow: var(--boxShadow-default);
    width: var(--button-size);
    min-width: var(--button-size);
    height: var(--button-size);
    border: var(--border-default);
    z-index: var(--zIndex-button);
}

.small-button img:has(+ p) {
    margin-right: var(--margin-xs);
}

button .plus {
    position: relative;
    width: 100%;
    height: 100%;
}

.high-z-index {
    z-index: 10000;
}

.button-gray {
    background-color: var(--color-gray) !important;
}

.dark .button-gray {
    color: var(--color-primary);
}

.button-gray:hover {
    filter: brightness(1) !important;
}

.check-icon {
    width: 40px;
    height: 40px;
    background-color: var(--color-risk-0);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-bottom: var(--margin-s);
}

/* ANIMATION STYLES */

.open {
    display: block !important;
    opacity: 1 !important;
}

.center-animation {
    animation: popup var(--transition-default) ease forwards;
}

.slide-in {
    animation: slide-in var(--transition-default) ease-out forwards;
}

.slide-out {
    animation: slide-out var(--transition-default) ease-in forwards;
}

.live {
    border-radius: 50%;
    background-color: var(--color-red);
}

.pulse {
    animation: pulse 3500ms infinite;
}

@keyframes pulse {
    0% {
        box-shadow: var(--color-red) 0 0 0 0;
    }
    50% {
        box-shadow: var(--color-red) 0 0 0 0;
    }
    75% {
        box-shadow: var(--boxShadow-marker-pulse);
    }
}

/* FLEX STYLES */

.center-child {
    display: flex;
    justify-content: center;
    align-items: center;
}

.align-child-on-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    width: 100%;
}

.align-child-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* ANIMATIONS */

@keyframes popup {
    0% {
        transform: translate(-50%, -50%) scale(0.5);
        opacity: 0;
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}

@keyframes slide-in {
    0% {
        transform: translateX(-50%) translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(-50%) translateY(0%);
        opacity: 1;
    }
}

@keyframes slide-out {
    0% {
        transform: translateX(-50%) translateY(0%);
        opacity: 1;
    }
    100% {
        transform: translateX(-50%) translateY(100%);
        opacity: 0;
    }
}

/* MEDIA QUERIES */

@media (max-width: 500px) {
    .modal {
        width: 85vw;
    }
}

/* Line colors */

.line {
    width: 35px;
    height: 25px;
    border-radius: var(--borderRadius-small);
    display: flex;
    justify-content: center;
    align-items: center;
}

.elapsed-time {
    font-size: var(--font-xs);
}

.expand-button {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
    min-width: 20px;
    width: 30px;
    height: 30px;
    padding: 0;
    outline: none;
}

.expand-button::before {
    content: '';
    position: absolute;
    margin-top: 8px;
    width: 10px;
    height: 10px;
    border-left: 2px solid var(--color-primary);
    border-top: 2px solid var(--color-primary);
    transform: translate(-50%, -50%) rotate(45deg);
    transition: transform var(--transition-default) ease;
    border-radius: 1px;
}

.expand-button.expanded::before {
    transform: translate(-50%, -75%) rotate(225deg);
}
