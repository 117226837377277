.list-button {
    height: auto;
    min-height: var(--height-streched-button);
    width: fit-content;
    bottom: var(--distance-from-edge-default);
    left: var(--distance-from-edge-default);
    overflow: hidden;
    margin-bottom: 15px;
    padding: var(--padding-s);
}

.list-button-content {
    display: flex;
    flex-direction: column;
    gap: var(--margin-xs);
    width: 100%;
}

.list-button-header {
    display: flex;
    align-items: center;
    gap: var(--margin-xs);
    border-bottom: 1px solid var(--color-light-gray);
}

.list-button-header p {
    font-size: var(--font-xxs);
    font-weight: 400;
}

.latest-report {
    display: flex;
    align-items: center;
    gap: var(--margin-xs);
    padding-top: var(--padding-xxs);
    margin-top: var(--margin-xxs);
}

.latest-report .station-name {
    font-size: var(--font-xs);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.indicator {
    height: 8px;
    width: 8px;
    border: 2px solid var(--color-white);
    margin-left: auto;
}
