button.close-button {
    top: -30px;
    right: 10px;
    z-index: var(--zIndex-closeButton);
    box-shadow: var(--boxShadow-light);
    width: 45px;
    height: 45px;
    cursor: pointer;
}

.close-button::after,
.close-button::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 4px;
    background-color: var(--color-primary);
    border-radius: 2px;
    transform-origin: center;
}

.close-button::after {
    transform: translate(-50%, -50%) rotate(45deg);
}

.close-button::before {
    transform: translate(-50%, -50%) rotate(-45deg);
}
