.inspector-marker {
    cursor: pointer;
    background-color: var(--color-red);
    border-radius: 50%;
    box-shadow: var(--boxShadow-marker);
    border: var(--border-marker);
}

.marker {
    height: 20px;
    width: 20px;
    display: block;
}
