.util-modal {
    display: flex !important;
    flex-direction: column;
    width: fit-content;
    min-width: 300px;
    padding: var(--padding-l);
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: var(--margin-l);
}

.modal-header h1 {
    margin: 0;
    margin-right: var(--margin-m);
    font-size: var(--font-l);
}

.modal-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: var(--margin-s);
}

.social-media-row {
    display: flex;
    gap: var(--margin-m);
}

.social-media-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    min-width: auto;
    width: auto;
    height: auto;
}

.social-media-button img {
    height: 20px;
    width: 20px;
}

.links-row {
    display: flex;
    gap: var(--margin-l);
}

.links-row a,
.links-row button {
    color: var(--color-primary);
    text-decoration: underline;
    font-size: var(--font-xs);
    height: fit-content;
}

.text-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    color: var(--color-primary);
    font: inherit;
    text-align: left;
}

.separator {
    width: 100%;
    height: 1px;
    background-color: var(--color-light-gray);
    margin: var(--margin-s) 0;
}

.toggle-switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.toggle-switch__label {
    font-size: var(--font-xs);
    color: var(--color-primary);
    user-select: none;
}

.toggle-switch__input {
    position: relative;
    width: 40px;
    height: 20px;
    appearance: none;
    background-color: var(--color-light-gray);
    border-radius: 20px;
    cursor: pointer;
    transition: background-color var(--transition-default);
}

.toggle-switch__input::before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    top: 2px;
    left: 2px;
    background-color: var(--color-white);
    transition: transform var(--transition-default);
}

.toggle-switch__input:checked {
    background-color: var(--incentive-blue);
}

.toggle-switch__input:checked::before {
    transform: translateX(20px);
}

@media (max-width: 500px) {
    .util-modal {
        width: 85%;
    }

    .modal-header h1 {
        font-size: var(--font-m);
    }

    .toggle-switch__label {
        font-size: var(--font-xxs);
    }
}
