button.util-button {
    top: var(--distance-from-edge-default);
    left: var(--distance-from-edge-default);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

button.util-button img {
    width: 35px;
    height: 35px;
}
